import React, { useRef, useState, useEffect } from "react";
import {
  Dialog,
  TextField,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import EmailEditor from "react-email-editor";
import { CustomOutlineButton } from "../../components/custom-button";
import {
  useSaveTemplate,
  useTemplateList,
  useCreateEmail,
} from "../../hooks/email.hooks";
import { useMyUserRole } from "../../hooks/user.hooks";
import { USER_ATTRIBUTES } from "./merge-tags";

const TemplateDropdown = ({ templateList, onSelectTemplate }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(
    templateList?.[0] ?? null
  );

  const handleTemplateChange = (template) => {
    setSelectedTemplate(template);
    onSelectTemplate(template.id, template.designContent, template.user.id);
  };

  return (
    <div className="template-dropdown-container">
      <select
        className="template-select"
        onChange={(e) => {
          const template = templateList.find((t) => t.id === e.target.value);
          handleTemplateChange(template);
        }}
        value={selectedTemplate?.id || ""}
      >
        <option value="" disabled>
          Select a template
        </option>
        {templateList?.map((template) => (
          <option key={template.id} value={template.id}>
            {template.templateName || `Template ${template.id}`}
          </option>
        ))}
      </select>

      {selectedTemplate && (
        <div className="template-preview">
          <h4>Preview</h4>
          <div className="preview-frame">
            <iframe
              srcDoc={selectedTemplate.htmlContent}
              title="Template Preview"
            />
          </div>
        </div>
      )}
    </div>
  );
};

const EmailEditorDialog = ({
  isOpen = false,
  emailList = [],
  period = 0,
  setIsOpenEmailEditorDialog,
  selectedTemplate,
  source = "default",
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showEditor, setShowEditor] = useState(false);
  const emailEditorRef = useRef(null);
  const saveTemplate = useSaveTemplate();
  const createEmail = useCreateEmail();
  const { data: userInfo } = useMyUserRole();
  const { data: templateList = [], refetch } = useTemplateList();
  const [activeTemplateId, setActiveTemplateId] = useState(
    selectedTemplate?.id
  );
  const [activeTemplate, setActiveTemplate] = useState(
    selectedTemplate?.designContent
  );
  const [templateName, setTemplateName] = useState(
    selectedTemplate?.templateName || ""
  );
  const [isNameDialogOpen, setIsNameDialogOpen] = useState(false);
  const [isEmailEditorLoading, setIsEmailEditorLoading] = useState(true);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (selectedTemplate) {
      setActiveTemplateId(selectedTemplate.id);
      setActiveTemplate(selectedTemplate.designContent);
      setTemplateName(selectedTemplate.templateName || "");
      setShowEditor(true);
      setIsEditable(
        userInfo?.id === selectedTemplate?.user?.id ||
          userInfo?.userRole === selectedTemplate?.user?.user_role
      );
    } else if (source === "email-center") {
      // For new template creation
      setActiveTemplateId(null);
      setActiveTemplate(null);
      setTemplateName("");
      setShowEditor(true);
    }
  }, [selectedTemplate, source]);

  const handleSelectTemplate = (id, template, userId) => {
    setActiveTemplateId(id);
    setActiveTemplate(template);
    if (template && emailEditorRef.current) {
      emailEditorRef.current.editor.loadDesign(template);
    }
    setIsEditable(userId === userInfo?.id);
  };

  const handleEditTemplate = () => {
    setIsEmailEditorLoading(true);
    setShowEditor(true);
    // Set the template name from the selected template
    const template = templateList.find((t) => t.id === activeTemplateId);
    if (template) {
      setTemplateName(template.templateName || "");
    }
  };

  const handleEditorReady = () => {
    setIsEmailEditorLoading(false);
    if (activeTemplate && emailEditorRef.current) {
      emailEditorRef.current.editor.loadDesign(activeTemplate);
    }
  };

  const handleSave = (templateId) => {
    if (emailEditorRef.current) {
      emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        saveTemplate.mutate(
          {
            templateName: templateName || "Untitled Template",
            htmlContent: html,
            designContent: design,
            templateId: templateId ?? null,
          },
          {
            onSuccess: () => {
              refetch();
              enqueueSnackbar(
                `Template ${templateId ? "updated" : "created"}`,
                { variant: "success", autoHideDuration: 2000 }
              );
              setIsNameDialogOpen(false);
              if (source === "email-center") {
                setIsOpenEmailEditorDialog(false);
              } else {
                setShowEditor(false);
              }
            },
            onError: (err) => {
              enqueueSnackbar(err.message || "Error saving template", {
                variant: "error",
              });
            },
          }
        );
      });
    }
  };

  const handleBack = () => {
    if (source === "email-center") {
      setIsOpenEmailEditorDialog(false);
    } else {
      setShowEditor(false);
    }
  };

  const handleSend = () => {
    createEmail.mutate(
      { emailList, templateId: activeTemplateId, period },
      {
        onSuccess: () => {
          refetch();
          enqueueSnackbar(`Email sent`, {
            variant: "success",
            autoHideDuration: 2000,
          });
          setIsOpenEmailEditorDialog(false);
        },
        onError: (err) => {
          enqueueSnackbar(err.message || "Error sending email", {
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen}
        onClose={() => setIsOpenEmailEditorDialog(false)}
      >
        <div className="email-dialog">
          {showEditor ? (
            <div id="email-container" className="editor-container">
              <div className="button-group">
                <CustomOutlineButton label="Back" onClick={handleBack} />
                {activeTemplateId ? (
                  <>
                    <CustomOutlineButton
                      label="Update Email"
                      onClick={() => handleSave(activeTemplateId)}
                      disabled={!isEditable}
                      disabledTooltip="You are not authorized to update this template"
                    />
                    <CustomOutlineButton
                      label="Save as New Email"
                      onClick={() => setIsNameDialogOpen(true)}
                    />
                  </>
                ) : (
                  <CustomOutlineButton
                    label="Save Email"
                    onClick={() => setIsNameDialogOpen(true)}
                  />
                )}
              </div>
              <div className="email-dialog__name-field">
                <Typography variant="subtitle2" color="textSecondary">
                  Template Name
                </Typography>
                <TextField
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                  variant="standard"
                  fullWidth
                  placeholder="Enter template name"
                  sx={{
                    "& .MuiInput-root": {
                      fontSize: "20px",
                      fontWeight: 500,
                    },
                    "& .MuiInput-root:before": {
                      borderBottom: "none",
                    },
                    "& .MuiInput-root:hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                    },
                  }}
                />
              </div>
              <React.StrictMode>
                <div className="loading-container">
                  {isEmailEditorLoading && <CircularProgress />}
                </div>
                <EmailEditor
                  ref={emailEditorRef}
                  onReady={handleEditorReady}
                  options={{
                    mergeTags: USER_ATTRIBUTES,
                  }}
                />
              </React.StrictMode>
            </div>
          ) : (
            <>
              <div className="button-group">
                <CustomOutlineButton
                  label="Edit Email"
                  onClick={handleEditTemplate}
                  disabled={!activeTemplate}
                />
                {source !== "email-center" && (
                  <CustomOutlineButton
                    label="Send"
                    onClick={handleSend}
                    disabled={!activeTemplate}
                  />
                )}
              </div>
              <TemplateDropdown
                templateList={templateList}
                onSelectTemplate={handleSelectTemplate}
              />
            </>
          )}
        </div>
      </Dialog>
      <Dialog
        open={isNameDialogOpen}
        onClose={() => setIsNameDialogOpen(false)}
      >
        <DialogTitle>Enter Template Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Template Name"
            fullWidth
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNameDialogOpen(false)}>Cancel</Button>
          <CustomOutlineButton
            label="Save"
            onClick={() => handleSave(null)}
            buttonType="primary"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmailEditorDialog;
